// src/components/Footer.jsx

import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer>
      Footer here
     <div class="fb-like" data-href="https://facebook.com/gotSucculentSucculents" data-width="400" data-layout="" data-action="" data-size="" data-share="true"></div>
    </footer>
  );
}

export default Footer;
