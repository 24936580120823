// src/components/ProductCard.jsx

import React from 'react';
import { motion } from 'framer-motion';

const ProductCard = ({ product, onAddToCart }) => {
  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 }
    },
    tap: { scale: 0.95 }
  };

  return (
    <motion.div 
      className="product-card" 
      initial="offscreen" 
      animate="onscreen"
    >
      <img src={product.imageUrl} alt={product.name} />
      <h3>{product.name}</h3>
      <p>{product.description}</p>
      <motion.button 
        variants={buttonVariants} 
        whileHover="hover" 
        whileTap="tap"
        onClick={() => onAddToCart(product)}
      >
        Add to Cart
      </motion.button>
    </motion.div>
  );
};

export default ProductCard;
