// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import AboutSection from './components/AboutSection';
import BlogPage from './components/BlogPage';
import ProductsPage from './components/ProductsPage';
import ProductsSection from './components/ProductsSection';
import CheckoutForm from './components/CheckoutForm'; // Import the CheckoutForm
import './App.css';

// Replace with your Stripe public key
const stripePromise = loadStripe('pk_test_51OQ7a6DMkG34lwVj3X2dFhy6BXzccg2JjVIfCeurJ470UEjjZWpJPHt23VWp9TvZeglG8zsNGOfSrpOguvfG73za00olLs9uSo');

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <RouteChangeDetector />
        <Footer />
      </div>
    </Router>
  );
}

export default App;

function RouteChangeDetector() {
  let location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          <Route path="/" element={
            <>
              <HeroSection />
              <ProductsSection showOnlyFeatured={true} />
              <AboutSection />
            </>
          } />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/checkout" element={
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          } />
          {/* Add other routes here */}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}
