// src/components/ProductsPage.jsx

import React from 'react';
import ProductsSection from './ProductsSection';

function ProductsPage() {
  return (
    <div>
      <ProductsSection showOnlyFeatured={false} />
    </div>
  );
}

export default ProductsPage;
