// src/components/CheckoutForm.jsx

import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import './CheckoutForm.css';

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [billingAddress, setBillingAddress] = useState({ line1: '', city: '', state: '', postal_code: '', country: '' });
  const [shippingAddress, setShippingAddress] = useState({ ...billingAddress });
  const [sameAsBilling, setSameAsBilling] = useState(true);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setBillingAddress(prev => ({ ...prev, [name]: value }));

    if (sameAsBilling) {
      setShippingAddress(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleShippingChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress(prev => ({ ...prev, [name]: value }));
  };

  const handleSameAsBilling = () => {
    setSameAsBilling(!sameAsBilling);
    if (!sameAsBilling) {
      setShippingAddress(billingAddress);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentStatus('Processing...');

    if (!stripe || !elements) {
      setPaymentStatus('Stripe has not loaded yet.');
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        email: email,
        address: {
          ...billingAddress
        }
      }
    });

    if (error) {
      console.log('[error]', error);
      setPaymentStatus(`Payment failed: ${error.message}`);
    } else {
      console.log('[PaymentMethod]', paymentMethod);
      // Handle the result and process the payment
      setPaymentStatus('Payment successful!');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <h2>Complete Your Purchase</h2>
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
      <input type="tel" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} placeholder="Phone Number" />
      
      <h3>Billing Address</h3>
      <input type="text" value={billingAddress.line1} name="line1" onChange={handleAddressChange} placeholder="Address Line 1" required />
      <input type="text" value={billingAddress.city} name="city" onChange={handleAddressChange} placeholder="City" required />
      <input type="text" value={billingAddress.state} name="state" onChange={handleAddressChange} placeholder="State" required />
      <input type="text" value={billingAddress.postal_code} name="postal_code" onChange={handleAddressChange} placeholder="ZIP / Postal Code" required />
      <input type="text" value={billingAddress.country} name="country" onChange={handleAddressChange} placeholder="Country" required />

      <h3>Shipping Address</h3>
      <input type="checkbox" checked={sameAsBilling} onChange={handleSameAsBilling} /> Same as Billing Address
      {!sameAsBilling && (
        <>
          <input type="text" value={shippingAddress.line1} name="line1" onChange={handleShippingChange} placeholder="Address Line 1" required />
          {/* Add other shipping address fields like city, state, postal_code, country */}
        </>
      )}

      <CardElement />
      <button type="submit" disabled={!stripe}>Pay</button>
      {paymentStatus && <div className="payment-status">{paymentStatus}</div>}
    </form>
  );
};

export default CheckoutForm;
