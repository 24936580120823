// src/components/AboutSection.jsx

import React from 'react';
import './AboutSection.css';

function AboutSection() {
  return (
    <section id="about">
      <h2>About Us</h2>
      <p>We are passionate about bringing the beauty of succulents to your home.</p>
    </section>
  );
}

export default AboutSection;
