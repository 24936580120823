// src/components/Header.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const giveawayEndDate = new Date('Jan 01, 2024 00:00:00').getTime();
    const intervalId = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeDifference = giveawayEndDate - currentTime;
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);
      const countdownText = `Next Giveaway: <b>${days} days ${hours} hours ${minutes} mins ${seconds}s</b>`;
      setCountdown(countdownText);

      if (timeDifference < 0) {
        clearInterval(intervalId);
        setCountdown('Giveaway Ended!');
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <header>
      <div id="countdown-timer" className="countdown-timer" dangerouslySetInnerHTML={{ __html: countdown }}></div>
      <Menu 
        isOpen={isMenuOpen}
        onStateChange={handleStateChange}
        right
      >
        <Link to="/" onClick={closeMenu}>Home</Link>
        <Link to="/about" onClick={closeMenu}>About Us</Link>
        <Link to="/products" onClick={closeMenu}>Products</Link>
        <Link to="/blog" onClick={closeMenu}>Blog</Link>
        <Link to="/reviews" onClick={closeMenu}>Customer Reviews</Link>
        <Link to="/cart" onClick={closeMenu}>Shopping Cart</Link>
        <Link to="/checkout" onClick={closeMenu}>Checkout</Link>
      </Menu>
    </header>
  );
}

export default Header;
