// src/components/HeroSection.jsx

import React from 'react';
import './HeroSection.css';

function HeroSection() {
  return (
    <section id="hero">
      <h1>Welcome! 🤗</h1>
      <img style={{ maxWidth: "90%" }} src="images/a-logo-for-Succulent-Succulents-an-online-succulen_2023-12-22T05-09-59.png" alt="Succulents" /><br />
      <p>Explore our range of hand-picked succulents and custom planters.</p>
      <button>Shop Now</button>
    </section>
  );
}

export default HeroSection;
