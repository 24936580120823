// src/components/BlogPage.jsx

import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './BlogPage.css';

const BlogPage = () => {
  // Dummy data for blog posts
  const blogPosts = [
    {
      id: 1,
      title: "Title of Article 1",
      summary: "This is a summary of the first article...",
      imageUrl: "images/Succulent-Succulents-Logo-2024-no-text.png",
      link: "/blog/title-of-article-1"
    },
    // Add more blog posts here
  ];

   // Animation variants
   const fadeIn = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  return (
    <div className="blog-page">
      {blogPosts.map((post) => (
        <motion.div 
          key={post.id}
          className="blog-post-card"
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <img src={post.imageUrl} alt={post.title} />
          <h2>{post.title}</h2>
          <p>{post.summary}</p>
          <Link to={post.link}>Read More</Link>
        </motion.div>
      ))}
    </div>
  );
};

export default BlogPage;
