// src/components/ProductsSection.jsx

import React from 'react';
import ProductCard from './ProductCard';
import products from '../products.json';
import './ProductsSection.css';

function ProductsSection({ showOnlyFeatured = false }) {
  const displayedProducts = showOnlyFeatured
    ? products.filter(product => product.isFeatured)
    : products;

  const handleAddToCart = (product) => {
    // TODO: Implement add-to-cart functionality
    console.log("Added to cart:", product.name);
  };

  return (
    <section id="products">
      <h2>{showOnlyFeatured ? 'Featured Products' : 'All Products'}</h2>
      <div className="product-grid">
        {displayedProducts.map(product => (
          <ProductCard 
            key={product.id} 
            product={product} 
            onAddToCart={handleAddToCart}
          />
        ))}
      </div>
    </section>
  );
}

export default ProductsSection;
